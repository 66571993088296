import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AssetsBack from "src/images/assets_back.svg";
import Footer from "src/components/Footer";
import { useTranslation } from "react-i18next";
import AlumniLogo from '../../images/alumni_logo.svg';

function PbForbiddenPage() {
    const { t } = useTranslation();

    useEffect(() => {
        document.body.classList.add(
            'use-style-guide'
        );
    });

    const navigate = useNavigate();
    const handleGoBack =  () => {
        navigate('/')
    }

    return (
        <>
            <div className='forbidden-content'>
                <div className="form-buttons">
                    <button type="button" className="btn btn-link-forbidden"
                            onClick={handleGoBack}>
                        <img src={AssetsBack} />
                        <span>  {t('back_btn')}</span>
                    </button>
                </div>
                <div className='centered-content'>
                    <div className="logo">
                        <img src={AlumniLogo}/>
                    </div>
                    <div className={"content-box"}>
                        <p>{t('forbidden_text_1')}</p>
                        <p>{t('forbidden_text_2')}</p>
                    </div>
                    <Footer forbidden={true}/>
                </div>
            </div>

        </>
    );
}

export default PbForbiddenPage;
