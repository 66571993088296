import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux';
import FaqModal from "src/components/FaqModal";
import LoginPickerScreen from "src/components/LoginPickerScreen";
import ReactRoutes from "src/constants/react-routes";
import store from 'src/redux/store';
import ContactHelpScreen from './alumni/ContactHelpScreen';
import ContactInfoSelectScreen from './alumni/ContactInfoSelectScreen';
import WelcomeScreen from './alumni/WelcomeScreen';
import Help from './alumni/Help';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react'
import PrivacyPolicyView from './alumni/PrivacyPolicyView';
import PbForbiddenPage from "src/components/error/PbForbidden";
import UserTypeSelectionScreen from './usertypes/UserTypeSelectionScreen';
import {
    showDeconsolidatedLogin,
    isPhaseTwo,
    isPhaseThree,
    showCsgoModal,
    showNewActivationFlow
} from 'src/constants/feature-gates';
import DeconsolidatedLoginScreen from './DeconsolidatedLoginScreen';
import CSGORegionSelectionScreen from './csgo/CSGORegionSelectionScreen';
import VerifyAccountScreen from "src/components/alumni/VerifyAccountScreen";
import CreateAccountScreen from "src/components/alumni/CreateAccountScreen";
import InvalidLinkScreen from "src/components/alumni/InvalidLinkScreen";

// @ts-ignore
const pbForbiddenShown: boolean =  '{{ShouldRenderPb}}' === 'true';

function App() {
    const deconsolidatedLoginEnabled = showDeconsolidatedLogin();
    const activationFlowEnabled = showNewActivationFlow();
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistStore(store)}>
                <BrowserRouter>
                    <Routes>
                        {deconsolidatedLoginEnabled ? 
                        <Route path={ReactRoutes.ROOT} element={<DeconsolidatedLoginScreen />} /> :  <Route path={ReactRoutes.ROOT} element={<LoginPickerScreen />} />}
                        <Route path={ReactRoutes.WELCOME} element={<WelcomeScreen />} />
                        <Route path={ReactRoutes.ALUMNI_WELCOME} element={<WelcomeScreen />} />
                        <Route path={ReactRoutes.ALUMNI_CONTACT_HELP} element={<ContactHelpScreen /> } />
                        <Route path={ReactRoutes.ALUMNI_CONTACT_SELECT} element={<ContactInfoSelectScreen /> } />
                        <Route path={ReactRoutes.ALUMNI_FAQ} element={<Help /> } />
                        <Route path={ReactRoutes.PRIVACY_POLICY} element={<PrivacyPolicyView /> } />
                        {activationFlowEnabled && <Route path={ReactRoutes.ALUMNI_VERIFY_ACCOUNT_REQUEST} element={<VerifyAccountScreen /> } />}
                        {activationFlowEnabled && <Route path={ReactRoutes.ALUMNI_CREATE_ACCOUNT} element={<CreateAccountScreen /> } />}
                        {activationFlowEnabled && <Route path={ReactRoutes.ALUMNI_ERROR_SCREEN} element={<InvalidLinkScreen />} />}
                        {pbForbiddenShown && <Route path={ReactRoutes.PREBOARDER_FORBIDDEN} element={<PbForbiddenPage />} /> }
                        {deconsolidatedLoginEnabled && (isPhaseTwo() || isPhaseThree()) && <Route path={ReactRoutes.SELECT_USERTYPE} element={<UserTypeSelectionScreen />} />}
                        {deconsolidatedLoginEnabled && showCsgoModal() && <Route path={ReactRoutes.CSGO_REGION} element={<CSGORegionSelectionScreen /> } />}
                    </Routes>
                </BrowserRouter>
                <FaqModal />
            </PersistGate>
        </Provider>
    );
}

export default App;
